<template>
    <base-card>
        <v-card-title
            v-if="title"
            class="title-post"
        >
            <span> {{ title }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <div
                v-if="!canManageGames && getImage"
                class="d-flex justify-center"
            >
                <img
                    :src="getImage"
                    height="100px"
                />
            </div>
            <div v-if="getBody">
                <span
                    class="ql-editor"
                    style="padding: 15px 0px"
                    v-html="getBody"
                />
            </div>

            <gameComponent
                v-if="post.attributes.game_id"
                :game-id="post.attributes.game_id"
            />

            <!--            <nextPostBtn
          :url-next-post="nextPost"
          @changed:post="updateIndexShowPost"
      />-->
        </v-card-text>
    </base-card>

    <!--    <div> {{ post && post.attributes && post.attributes.body }} </div>-->
</template>

<script>
import { mapGetters } from 'vuex';
import postMixin from '@/mixins/postMixin';
import gameComponent from '@/components/games/gameComponnent';
// import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'GamePostView',
    components: { gameComponent /*, nextPostBtn */ },
    mixins: [postMixin],
    data: () => ({}),
    computed: {
        title() {
            const attributes = this.post.attributes;

            return attributes.title2; // ?? attributes.title
        },

        getImage() {
            if (this.post.attributes.image2) {
                return (
                    process.env.VUE_APP_API_ENDPOINT +
                    'media/imagesSimple?filename=posts/images/' +
                    this.post.attributes.image2
                );
            }

            return '';
        },
        getBody() {
            return this.canManageGames ? this.post?.attributes?.bodyAdmin : this.post?.attributes?.body;
        },
        ...mapGetters(['isAdmin']),
        ...mapGetters('permissions', ['canManageGames'])
    },
    created() {},
    methods: {}
};
</script>

<style lang="css" scoped>
/*@import "~vue2-editor/dist/vue2-editor.css";*/

/* Import the Quill styles you want */
/*@import '~quill/dist/quill.core.css';*/
/*@import '~quill/dist/quill.bubble.css';*/

/*@import '~quill/dist/quill.snow.css';*/
</style>
